import {App} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import {AxiosResponse, AxiosRequestConfig} from "axios";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @param params
   * @returns Promise<AxiosResponse>
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    params: any = undefined,
    slug        = "" as string,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`, {params: params});
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    axios.interceptors.request.use(function(config) {
      let timerInterval
      Swal.fire({
          title: 'Wait!!!',
          timerProgressBar: true,
          onBeforeOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                  const content = Swal.getContent()
                  if (content) {
                      const b = content.querySelector('b')
                      if (b) {
                          b.textContent = Swal.getTimerLeft()
                      }
                  }
              }, 100)
          },
          onClose: () => {
              clearInterval(timerInterval)
          },
          customClass: {
            container: 'progress-swal'
          }        
      }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
              // console.log('I was closed by the timer')
          }
      })

      return config;
    }, function(error) {
      // Do something with request error
      Swal.close();
      return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      Swal.close();
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      Swal.close();

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
      
      return Promise.reject(error);
    });

    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param data
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string, data?: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource, {data: data});
  }
}

export default ApiService;
