const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRegisterToken = (): string | null => {
  return window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveRegisterToken = async (token: string): Promise<void> => {
  await window.localStorage.setItem(process.env.VUE_APP_REGISTER_TOKEN_NAME, token);
};


/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRegisterToken = (): void => {
  window.localStorage.removeItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);
};

export default {getToken, saveToken, destroyToken, getRegisterToken, saveRegisterToken, destroyRegisterToken};
