import JwtService from "@/core/services/JwtService";
import jwtService from "@/core/services/JwtService";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {MemberModel} from "@/models/registration/member-model";
import router from "@/router";
import ApiRegisterService from "@/core/services/ApiRegisterService";
import {AxiosResponse} from "axios";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

@Module
export default class RegistrationModule extends VuexModule {
  isAuthenticated = !!JwtService.getRegisterToken();
  registration = {} as MemberModel;
  loading: boolean = false;

  get getRegisterData(): MemberModel {
    return this.registration;
  }

  get getRegisterLoading(): boolean {
    return this.loading;
  }

  get isRegisterAuth() {
    return this.isAuthenticated;
  }

  @Mutation
  ['setRegisterLoading'](load: boolean) {
    this.loading = load;
  }

  @Mutation
  ['setRegisterData'](data: any) {
    this.registration = data;
  }

  @Mutation
  async ['setRegisterAuth'](token) {
    this.isAuthenticated = true;
    await JwtService.saveRegisterToken(token);
  }

  @Action
  ['getRegisterMemberAction'](uuid: any) {
    this.context.commit('setRegisterLoading', true);
    const service = new ApiRegisterService(uuid);

    service.getDetailMember()
      .then((res: AxiosResponse) => {
        this.context.commit('setRegisterData', res.data.data);
      })
      .catch(async ({response}) => {
        await service.exceptionHandler(response);
      }).finally(() => {
      this.context.commit('setRegisterLoading', false);
    });
  }

  @Action
  ['getRegisterTokenAction'](uuid?: any) {
    this.context.commit('setRegisterLoading', true);
    jwtService.destroyRegisterToken();

    const service = new ApiRegisterService();
    return service.verify()
      .then((res: AxiosResponse) => {
        this.context.commit('setRegisterAuth', res.data.access_token);

        if (uuid) {
          this.context.dispatch('getRegisterMemberAction', uuid);
        }
      })
      .catch(async ({response}) => {
        if (response) {
          if (response.status === 401) {
            await router.push({name: '404'});
          } else {
            Swal.fire({
              title            : "Proses Verifikasi Gagal",
              icon             : "error",
              buttonsStyling   : false,
              confirmButtonText: "Ok",
              customClass      : {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            })
          }
        } else {
          Swal.fire({
            title            : "Gagal mengambil data",
            html             : "silahkan refresh halaman",
            icon             : "error",
            buttonsStyling   : false,
            confirmButtonText: "Ok",
            customClass      : {
              confirmButton: "btn fw-semobold btn-light-primary",
            },
          }).then(() => {
            window.location.reload();
          })
        }
      }).finally(() => {
        this.context.commit('setRegisterLoading', false);
      });
  }
}
