import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path     : "/",
    redirect : "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta     : {
      middleware: "auth",
    },
    children : [
      {
        path     : "/dashboard",
        name     : "dashboard",
        component: () => import("@/views/main/Dashboard.vue"),
        meta     : {
          pageTitle  : "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path     : "/setting/user-management",
        name     : "user-management",
        component: () => import("@/views/main/user-management/UserManagement.vue"),
        meta     : {
          pageTitle  : "User Management",
          breadcrumbs: ["Setting", "User Management"],
        },
      },
      {
        path     : "/setting/user-role/list",
        name     : "user-role-list",
        component: () => import("@/views/main/user-role/UserRole.vue"),
        meta     : {
          pageTitle  : "User Role",
          breadcrumbs: ["Setting", "User Role"],
        },
      },
      {
        path     : "/setting/user-role/role-permission/:roleId",
        name     : "role-permission",
        component: () => import("@/views/main/user-role/RolePermission.vue"),
        meta     : {
          pageTitle  : "Role Permission",
          breadcrumbs: ["Setting", "Role Permission"],
        },
      },
      {
        path     : "/setting/permission",
        name     : "permission",
        component: () => import("@/views/main/permission/Permission.vue"),
        meta     : {
          pageTitle  : "Permission",
          breadcrumbs: ["Setting", "Permission"],
        },
      },
      {
        path     : "/master/education-program",
        name     : "education-program",
        component: () => import("@/views/main/master/education-program/EducationProgram.vue"),
        meta     : {
          breadcrumbs: ["Master", "Program Pendidikan"],
        },
        children : [
          {
            path     : "fellowship",
            name     : "education-program-fellowship",
            component: () =>
              import("@/views/main/master/education-program/Fellowship.vue"),
            meta     : {
              pageTitle: "Fellowship",
            },
          },
          {
            path     : "subspecialist",
            name     : "education-program-specialist",
            component: () =>
              import("@/views/main/master/education-program/SubSpecialist.vue"),
            meta     : {
              pageTitle: "Subspesialis",
            },
          },
          {
            path     : "subspecialist/sub-specialist-fellowship/:subspecialistId",
            name     : "education-program-sub-specialist-fellowship",
            component: () =>
              import("@/views/main/master/education-program/SubSpecialistFellowship.vue"),
            meta     : {
              pageTitle: "Subspesialis Fellowship",
            },
          },
        ]
      },
      {
        path     : "/master/hospital",
        name     : "master-hospital",
        component: () => import("@/views/main/master/hospital/Hospital.vue"),
        meta     : {
          pageTitle  : "Rumah Sakit",
          breadcrumbs: ["Master", "Rumah Sakit"],
        },
      },
      {
        path     : "/master/hospital/student-registered/:id",
        name     : "hospital-student",
        component: () => import("@/views/main/master/hospital/HospitalStudent.vue"),
        meta     : {
          pageTitle  : "Daftar Mahasiswa",
          breadcrumbs: ["Master", "Rumah Sakit", "Daftar Mahasiswa"],
        },
      },
      {
        path     : "/master/university",
        name     : "master-university",
        component: () => import("@/views/main/master/university/University.vue"),
        meta     : {
          pageTitle  : "Universitas",
          breadcrumbs: ["Master", "Universitas"],
        },
      },
      {
        path     : "/master/university/detail",
        name     : "university-detail",
        component: () => import("@/views/main/master/university/detail/DetailUniversity.vue"),
        meta     : {
          pageTitle: "Detail Universitas",
        },
        children : [
          {
            path     : "subspecialist/:universityId",
            name     : "university-detail-subspecialist",
            component: () =>
              import("@/views/main/master/university/detail/UniversitySubspecialist.vue"),
            meta     : {
              pageTitle  : "Universitas Subspesialis",
              breadcrumbs: ["Master", "Universitas", "Subspesialis"],
            },
          },
          {
            path     : "fellowship/:universityId",
            name     : "university-detail-fellowship",
            component: () =>
              import("@/views/main/master/university/detail/UniversityFellowship.vue"),
            meta     : {
              pageTitle  : "Universitas Fellowship",
              breadcrumbs: ["Master", "Universitas", "Fellowship"],
            },
          },
        ]
      },
      {
        path     : "/master/university/detail-action",
        name     : "university-detail-action",
        component: () => import("@/views/main/master/university/detail-action/DetailUniversity.vue"),
        meta     : {
          pageTitle: "Detail Universitas",
        },
        children : [
          {
            path     : "subspecialist/:universityId",
            name     : "university-subspecialist",
            component: () =>
              import("@/views/main/master/university/detail-action/UniversitySubspecialist.vue"),
            meta     : {
              pageTitle  : "Universitas Subspesialis",
              breadcrumbs: ["Master", "Universitas", "Subspesialis"],
            },
          },
          {
            path     : "fellowship/:universityId",
            name     : "university-fellowship",
            component: () =>
              import("@/views/main/master/university/detail-action/UniversityFellowship.vue"),
            meta     : {
              pageTitle  : "Universitas Fellowship",
              breadcrumbs: ["Master", "Universitas", "Fellowship"],
            },
          },
        ]
      },
      {
        path     : "/reception-period",
        name     : "index-reception-period",
        component: () => import("@/views/main/reception-period/ReceptionPeriod.vue"),
        meta     : {
          pageTitle: "Periode Pendidikan",
        },
      },
      {
        path     : "/reception-period/:id",
        name     : "detail-reception-period",
        component: () => import("@/views/main/reception-period/detail/DetailReceptionPeriod.vue"),
        meta     : {
          pageTitle  : "Periode Pendidikan",
          breadcrumbs: ["Periode Pendidikan", "Detail"],
        },
      },
      {
        path     : "/reception-period/create",
        name     : "create-reception-period",
        component: () => import("@/views/main/reception-period/CreateReceptionPeriodForm.vue"),
        meta     : {
          pageTitle  : "Tambah Periode",
          breadcrumbs: ["Periode Pendidikan", "Tambah"],
        },
      },
      {
        path     : "/reception-period/update/:id",
        name     : "update-reception-period",
        component: () => import("@/views/main/reception-period/UpdateReceptionPeriod.vue"),
        meta     : {
          pageTitle  : "Periode Pendidikan",
          breadcrumbs: ["Periode Pendidikan", "Update"],
        },
      },
      {
        path     : "/reception-period/registration-not-finish/:id",
        name     : "registration-not-finish",
        component: () => import("@/views/main/reception-period/RegistrationNotFinish.vue"),
        meta     : {
          pageTitle  : "Daftar Mahasiswa Belum Selesai Registrasi",
          breadcrumbs: ["Periode Pendidikan", "Daftar Mahasiswa Belum Selesai Registrasi"],
        },
      },
      {
        path     : "/registration-file",
        name     : "registration-file-index",
        component: () => import("@/views/main/registration-file/RegistrationFile.vue"),
        meta     : {
          pageTitle  : "Berkas Pendaftaran",
          breadcrumbs: ["Berkas Pendaftaran"],
        },
      },
      {
        path     : "/student-new",
        name     : "student-new",
        component: () => import("@/views/main/student/student-new/StudentNew.vue"),
        meta     : {
          pageTitle  : "Mahasiswa Baru",
          breadcrumbs: ["Mahasiswa Baru"],
        },
      },
      // {
      //   path     : "/student-new/show/:id",
      //   name     : "student-new-show",
      //   component: () => import("@/views/main/student/student-new/StudentNewShow.vue"),
      //   meta     : {
      //     pageTitle  : "Review Mahasiswa Baru",
      //     breadcrumbs: ["Mahasiswa Baru", "Review"],
      //   },
      // },
      {
        path     : "/student-new/verify/:id",
        name     : "student-new-verify",
        component: () => import("@/views/main/student/student-new/StudentNewVerify.vue"),
        meta     : {
          pageTitle  : "Review Mahasiswa Baru",
          breadcrumbs: ["Mahasiswa Baru", "Review"],
        },
      },
      {
        path     : "/student-new/interview-form/:id",
        name     : "student-interview-form",
        component: () => import("@/views/main/student/student-new/InterviewForm.vue"),
        meta     : {
          pageTitle  : "Input Hasil Wawancara",
          breadcrumbs: ["Mahasiswa Baru", "Hasil Interview"],
        },
      },
      {
        path     : "/student-new/schedule-interview-form/:id",
        name     : "student-schedule-interview-form",
        component: () => import("@/views/main/student/student-new/ScheduleInterviewForm.vue"),
        meta     : {
          pageTitle  : "Input Jadwal Wawancara",
          breadcrumbs: ["Mahasiswa Baru", "Jadwal Wawancara"],
        },
      },
      {
        path     : "/student-new/edit/:id",
        name     : "student-new-edit",
        component: () => import("@/views/main/student/student-new/StudentNewEdit.vue"),
        meta     : {
          pageTitle  : "Edit Mahasiswa Baru",
          breadcrumbs: ["Mahasiswa Baru", "Edit"],
        },
      },
      {
        path     : "/student-active",
        name     : "student-active",
        component: () => import("@/views/main/student/student-active/StudentActive.vue"),
        meta     : {
          pageTitle  : "Mahasiswa Aktif",
          breadcrumbs: ["Mahasiswa Aktif"],
        },
      },
      {
        path     : "/student/show/:id",
        name     : "student-new-show",
        component: () => import("@/views/main/student/student-new/StudentNewShow.vue"),
        meta     : {
          pageTitle  : "View Mahasiswa",
          breadcrumbs: ["Mahasiswa", "View"],
        },
      },
      {
        path     : "/student-finish",
        name     : "student-finish",
        component: () => import("@/views/main/student/student-done/StudentDone.vue"),
        meta     : {
          pageTitle  : "Mahasiswa Lulus",
          breadcrumbs: ["Mahasiswa Lulus"],
        },
      },
      {
        path     : "/report/fellowship",
        name     : "report-fellowship",
        component: () => import("@/views/main/report/Fellowship.vue"),
        meta     : {
          pageTitle  : "Laporan Rekap Pendaftaran",
          breadcrumbs: ["Report"],
        },
      },
      {
        path     : "/report/hospital",
        name     : "report-hospital",
        component: () => import("@/views/main/report/Hospital.vue"),
        meta     : {
          pageTitle  : "Laporan Rekap Pendaftaran Berdasarkan RS dan Fellow",
          breadcrumbs: ["Report"],
        },
      },
      {
        path     : "/report/payment",
        name     : "report-payment",
        component: () => import("@/views/main/report/Payment.vue"),
        meta     : {
          pageTitle  : "Laporan Pembayaran Peserta",
          breadcrumbs: ["Report"],
        },
      },
    ],
  },
  {
    path     : "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children : [
      {
        path     : "/sign-in",
        name     : "sign-in",
        component: () =>
          import("@/views/main/auth/SignIn.vue"),
        meta     : {
          pageTitle: "Sign In",
        },
      },
    ],
  },
  {
    path     : "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children : [
      {
        // the 404 route, when none of the above matches
        path     : "/404",
        name     : "404",
        component: () => import("@/views/Error404.vue"),
        meta     : {
          pageTitle: "Error 404",
        },
      },
      {
        path     : "/500",
        name     : "500",
        component: () => import("@/views/Error500.vue"),
        meta     : {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path     : "/",
    component: () => import("@/layouts/register-layout/RegisterLayout.vue"),
    children : [
      {
        path     : "form",
        name     : 'form',
        meta     : {
          middleware: "auth-register",
        },
        component: () => import("@/views/register/Register.vue"),
        children : [
          {
            path     : "dashboard",
            name     : "register-dashboard",
            component: () => import("@/views/register/Dashboard.vue"),
            meta     : {
              pageTitle  : "Form Registration",
              breadcrumbs: ["Registration"],
            },
          },
          {
            path     : "first/:uuid",
            name     : "register-first",
            component: () => import("@/views/register/RegisterFirst.vue"),
            meta     : {
              pageTitle  : "Form Registration",
              breadcrumbs: ["Registration"],
            },
          },
          {
            path     : "second/:uuid",
            name     : "register-second",
            component: () => import("@/views/register/RegisterSecond.vue"),
            meta     : {
              pageTitle  : "Form Registration",
              breadcrumbs: ["Registration"],
            },
          },
          {
            path     : "third/:uuid",
            name     : "register-third",
            component: () => import("@/views/register/RegisterThird.vue"),
            meta     : {
              pageTitle  : "Form Registration",
              breadcrumbs: ["Registration"],
            },
          },
        ],
      },
      {
        path     : "/form/:uuid",
        name     : "register-home",
        component: () => import("@/views/register/RegisterHome.vue"),
        meta     : {
          pageTitle  : "Form Registration",
          breadcrumbs: ["Form"],
        },
      },
      {
        path     : "/form/finish",
        name     : "register-finish",
        component: () => import("@/views/register/RegisterFinish.vue"),
        meta     : {
          pageTitle  : "Form Registration",
          breadcrumbs: ["Form"],
        },
      },
      {
        path     : "/form/review/:uuid",
        name     : "register-review",
        component: () => import("@/views/register/RegisterReview.vue"),
        meta     : {
          pageTitle  : "Form Registration",
          breadcrumbs: ["Form"],
        },
      },
      {
        path     : "/form/pre-register",
        name     : "pre-register",
        component: () => import("@/views/register/pre-register/PreRegister.vue"),
        meta     : {
          pageTitle  : "Form Registration",
          breadcrumbs: ["Form"],
        },
      },
    ],
  },
  {
    path    : "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_URL : "/"),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);


  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    // verify auth token before each page change
    if (!store.getters.getProfile.id) {
      store.dispatch(Actions.VERIFY_AUTH, {api_token: JwtService.getToken()});
    }

    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({name: "sign-in"});
    }
  } else if (to.meta.middleware === "auth-register") {
    if (store.getters.isRegisterAuth) {
      if (!store.getters.getRegisterData.member) {
        store.dispatch('getRegisterMemberAction', to.params.uuid);
      }
      next();
    } else {
      next({name: "register-home", params: {uuid: to.params.uuid}});
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top     : 0,
    left    : 0,
    behavior: "smooth",
  });
});

export default router;
