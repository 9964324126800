import {AxiosResponse} from "axios";
import router from "@/router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "@/store"
import {Actions} from "@/store/enums/StoreEnums";

export class ApiExceptionHandler {
  static async execute(response: AxiosResponse) {
    if (response && response?.status) {
      if (response.status === 401) {
        await store.dispatch(Actions.LOGOUT);

        Swal.fire({
          title            : "Anda belum login, silahkan login dahulu",
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        }).then(() => {
          router.push({name: "sign-in"})
        });
      } else if (response.status === 400) {
        Swal.fire({
          title            : response.data.message,
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        })
      } else if (response.status === 404) {
        await router.push({name: "404"});
      } else if (response.status === 500) {
        Swal.fire({
          title            : "Terjadi Kesalahan Server",
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        })
      } else if (response.status === 429) {
        Swal.fire({
          title            : "Terlalu banyak request",
          html             : "Silahkan kembali beberapa saat lagi",
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        })
      }
    } else {
      Swal.fire({
        title            : "Gagal mengambil data",
        html             : "Silahkan refresh halaman",
        icon             : "error",
        buttonsStyling   : false,
        confirmButtonText: "OK",
        customClass      : {
          confirmButton: "btn fw-semobold btn-primary",
        },
      })
    }

  }
}