import axios, {AxiosInstance, AxiosResponse} from "axios";
import {RegistrationSubmitModel} from "@/models/registration/registration-submit.model";
import router from "@/router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

class ApiRegisterService {

  public axiosInstance: AxiosInstance = axios.create();
  public uuid: any;

  constructor(uuid?: any) {
    this.axiosInstance.defaults.baseURL = `${process.env.VUE_APP_BACKEND_URL}`;

    if (uuid) {
      this.uuid = uuid;
      this.axiosInstance.defaults.headers.common['MEMBER-UUID'] = this.uuid;
    }
  }

  public async exceptionHandler(response: AxiosResponse) {

    if (response && response?.status) {
      if (response.status === 401) {
        await router.push({name: 'register-home', params: {uuid: this.uuid}});
      } else if (response.status === 400) {
        Swal.fire({
          title            : response.data.message,
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        })
      } else if (response.status === 404) {
        await router.push({name: "404"});
      } else if (response.status === 500) {
        Swal.fire({
          title            : "Terjadi Kesalahan Server",
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        })
      } else if (response.status === 429) {
        Swal.fire({
          title            : "Gagal mengambil data",
          html             : "Silahkan refresh halaman dalam 10detik",
          icon             : "error",
          buttonsStyling   : false,
          confirmButtonText: "OK",
          customClass      : {
            confirmButton: "btn fw-semobold btn-primary",
          },
        })
      }
    } else {
      Swal.fire({
        title            : "Gagal mengambil data",
        html             : "Silahkan refresh halaman",
        icon             : "error",
        buttonsStyling   : false,
        confirmButtonText: "OK",
        customClass      : {
          confirmButton: "btn fw-semobold btn-primary",
        },
      })
    }
  }

  public async verify() {

    const credential = {
      grant_type   : "client_credentials",
      client_id    : process.env.VUE_APP_FORM_CLIENT_ID,
      client_secret: process.env.VUE_APP_FORM_CLIENT_SECRET,
      scope        : "*"
    };

    return await this.axiosInstance.post("oauth/token", credential);
  }

  public async getDetailMember() {
    const path = 'api/form-registration/member';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    return await this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  }

  public getUniversity() {
    const path = 'api/form-registration/list/ref-universitas';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  }

  public uploadFile(file: File) {
    const path = 'api/form-registration/file-upload';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    let formData = new FormData();
    formData.append('file', file);

    return this.axiosInstance.post(path, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type" : "multipart/form-data",
      },
    });
  }

  public submitForm(formModel: RegistrationSubmitModel) {
    const path = 'api/form-registration/submit';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    return this.axiosInstance.post(path, formModel, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  }

  public getEducationProgram(programType: any) {
    const path = 'api/form-registration/list/program-education';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params : {
        program_type: programType,
      },
    });
  }

  public getEducationHospital(programType: any, educationId: any) {
    const path = 'api/form-registration/list/hospital';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    let params;

    if (programType === "1" || programType === 1) {
      params = {
        program_type : programType,
        fellowship_id: educationId,
      };
    } else {
      params = {
        program_type    : programType,
        subspecialist_id: educationId,
      };
    }

    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params : params,
    })
  }

  public getEducationUniversity(hospitalId: any) {
    const path = 'api/form-registration/list/universitas';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);


    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params : {
        hospital_id: hospitalId,
      },
    })
  }

  public getRegistrationFile() {
    const path = 'api/form-registration/list/registration-file';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);


    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
  }

  public uploadDocument(request: {
    file: File,
    registrationId: any,
    registrationFileId: any,
  }) {
    const path = 'api/form-registration/upload-requirement-file';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    let formData = new FormData();
    formData.append('file', request.file);
    formData.append('registration_id', request.registrationId);
    formData.append('registration_file_id', request.registrationFileId);

    return this.axiosInstance.post(path, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type" : "multipart/form-data",
      },
    })
  }

  public checkNIK(nik: any) {
    const path = 'api/form-registration/member/nik';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params : {
        nik: nik,
      },
    })
  }

  public getReceptionPeriod() {
    const path = 'api/form-registration/reception-period';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);


    return this.axiosInstance.get(path, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
  }

  public getLinkRegistration(memberId: any, periodId: any) {
    const path = 'api/form-registration';
    const token = window.localStorage.getItem(process.env.VUE_APP_REGISTER_TOKEN_NAME);

    return this.axiosInstance.post(path, {
      member_id: memberId,
      period_id: periodId,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
  }
}

export default ApiRegisterService;
