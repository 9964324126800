import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {LoginResponseModel} from "@/store/models/auth/LoginResponseModel";
import {ProfileModel} from "@/models/profile.model";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";
import {ApiExceptionHandler} from "@/core/exception/ApiExceptionHandler";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

type Error = {
  status: number;
  message: string | null;
  data: unknown;
}

export interface UserAuthInfo {
  errors: Error;
  user: LoginResponseModel;
  isAuthenticated: boolean;
  profile: ProfileModel;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {} as Error;
  user = {} as LoginResponseModel;
  isAuthenticated = !!JwtService.getToken();
  message: string | null = null;
  profile = {} as ProfileModel;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): LoginResponseModel {
    return this.user;
  }

  /**
   * Get profile
   * @returns ProfileModel
   */
  get getProfile(): ProfileModel {
    return this.profile;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](res: any) {
    this.isAuthenticated = true;
    this.user = res.data;
    this.errors = {} as Error;
    JwtService.saveToken(res.data.token.access_token);
  }

  @Mutation
  ['setProfile'](res: any) {
    this.profile = res.data;
    this.isAuthenticated = true;
    this.errors = {} as Error;
  }


  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as LoginResponseModel;
    this.errors = {} as Error;
    this.profile = {} as ProfileModel;
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login", credentials)
      .then((res) => {
        this.context.commit(Mutations.SET_AUTH, res.data);

        Swal.fire({
          title            : "Anda berhasil login",
          icon             : "success",
          buttonsStyling   : false,
          confirmButtonText: "Ok",
          customClass      : {
            confirmButton: "btn fw-semobold btn-light-primary",
          },
        }).then(function () {
          // Go to page after successfully login
          router.push({name: "dashboard"});
        });
      })
      .catch(({response}) => {
        if (response) {
          let error = {} as Error;

          if (response.status === 422) {
            if (response.data.errors) {
              error.data = response.data.errors;
            }
            //
            this.context.commit(Mutations.SET_ERROR, error);

          } else if (response.status === 401) {
            Swal.fire({
              title            : response.data.message,
              icon             : "error",
              buttonsStyling   : false,
              confirmButtonText: "OK",
              customClass      : {
                confirmButton: "btn fw-semobold btn-primary",
              },
            })

          } else {
            ApiExceptionHandler.execute(response);
          }

        } else {
          this.context.commit(Mutations.SET_ERROR, "Unknow Error");
        }

      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({data}) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({response}) => {
        if (response.data.message) {
          this.context.commit("setMessage", response.data.message);
        }

        if (response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({response}) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    ApiService.setHeader();
    ApiService.get("profile")
      .then((res) => {
        this.context.commit('setProfile', res.data);
        //
        // console.log(res.data);
      })
      .catch(({response}) => {
        if (response) {
          ApiExceptionHandler.execute(response);
        }
      });
    // if (JwtService.getToken()) {
    // } else {
    //   this.context.commit(Mutations.PURGE_AUTH);
    // }
  }
}
