import {createApp} from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import {initApexCharts} from "@/core/plugins/apexcharts";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {initVeeValidate} from "@/core/plugins/vee-validate";

import 'bootstrap/dist/js/bootstrap.bundle.js'

//Prime Vue
import PrimeVue from 'primevue/config';
import Toolbar from "primevue/toolbar";
import OverlayPanel from "primevue/overlaypanel";
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Paginator from 'primevue/paginator';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Column from 'primevue/column';
import Calendar from "primevue/calendar";
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import SplitButton from 'primevue/splitbutton';

import "@/core/plugins/prismjs";

import "primevue/resources/primevue.min.css"              //core css
import "primeicons/primeicons.css"                          //icons

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(PrimeVue);

//Prime Vue
app.component('Toolbar', Toolbar);
app.component('OverlayPanel', OverlayPanel);
app.component('DataTable', DataTable);
app.component('Paginator', Paginator);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Menu', Menu);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Dropdown', Dropdown);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch ', InputSwitch);
app.component('SplitButton ', SplitButton);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
