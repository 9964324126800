import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
  general      : {
    mode        : "light",
    primaryColor: "#50CD89",
    pageWidth   : "default",
    layout      : "light-sidebar",
  },
  header       : {
    display: true,
    default: {
      container: "fluid",
      fixed    : {
        desktop: true,
        mobile : false,
      },
      menu     : {
        display : false,
        iconType: "svg",
      },
    },
  },
  sidebar      : {
    display: true,
    default: {
      minimize: {
        desktop: {
          enabled  : true,
          default  : false,
          hoverable: true,
        },
      },
      menu    : {
        iconType: "font",
      },
    },
  },
  toolbar      : {
    display  : true,
    container: "fluid",
    fixed    : {
      desktop: false,
      mobile : false,
    },
  },
  pageTitle    : {
    display   : true,
    breadcrumb: true,
    direction : "column",
  },
  content      : {
    container: "fluid",
  },
  footer       : {
    display  : false,
    container: "fluid",
    fixed    : {
      desktop: false,
      mobile : false,
    },
  },
  illustrations: {
    set: "sketchy-1",
  },
  scrolltop    : {
    display: false,
  },
};

export default config;
